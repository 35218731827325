import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './Layout/Navbar';
import { Stack } from '@mui/material';
import Footer from './Layout/Footer';
import ContactF from './Layout/ContactF';
function Layout({ children }) {
  return (
     <>
      <Helmet>
      <link rel="icon" href="/logo.svg" />
        <title>alpha2x</title>
      </Helmet>
      <body style={{background:"#f5f5f5"}}>
      <Stack>
      <Navbar/>
      <Stack marginTop={{xs:"70.04px",  md:"85.05px"}}>
      {children}
      </Stack>
      <ContactF/>
      <Footer/>
      </Stack>
      </body>
    </>
    
  );
}

export default Layout;
