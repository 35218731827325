import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Tolling/Browse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Parking = () => {
    return (
        <>
            {/* <Box
                component="img"
                src="./images/Tolling/tollbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px", md: "250px", lg: "400px" }
                }}
            /> */}
            <video width="400" autoPlay muted playsInline loop style={{ width: "100%" }} className="video" poster="./images/Tolling/tollban.jpg">
                <source src="./images/Tolling/tollingvideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Stack position="absolute" sx={{ top:{xs:"25vw",md:"15vw"} }} justifyContent="center" alignItems="center" width="100%">
                {/* <Typography color="#f5f5f5" fontSize={{xs:"24px",sm:"35px",md:"50px"}} fontWeight="600" textAlign="center">
                Tolling Solutions
                </Typography> */}
                <Typography color="#f5f5f5f5" fontSize={{xs:"18px",sm:"28px",md:"35px"}} margin="0px 10px" fontWeight="600" textAlign="center">
                Empowering Industries<br/>Driving Technological Advancements for a Smarter Tomorrow
                </Typography>
                <a href="#cont"
                ><ExpandMoreIcon sx={{
                    color: "#f5f5f5a6", fontWeight: "bold", fontSize: "40px", minHeight: "50px",
                    minWidth: "50px", border: "1px solid white",
                    borderRadius: " 50%", marginTop:{sm:"40px",md:"80px"},display:{xs:"none",sm:"block"}
                }} /></a>
            </Stack>
            <div id="cont"></div>
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-50px", sm: "-70px", md: "-120px", lg: "-150px" }}>
                <Stack sx={{ background: "#485461",backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Tolling </Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                    At Alpha2x, we specialize in providing cutting-edge solutions in the tolling industry. Our expertise lies in developing innovative cloud-based Back Office Systems (BOS) for streamlined toll management. We understand the challenges faced by tolling agencies and offer forward-looking strategies to optimize operations and enhance user experience.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Tolling/tolling.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Lack of Standardization and Interoperability:</strong> The tolling industry often faces challenges related to standardization and interoperability across different agencies and regions, leading to complexities in toll collection and system integration.<br /><br />
                        <strong>Dynamic Pricing and Revenue Optimization:</strong> Implementing dynamic pricing strategies and optimizing revenue generation while ensuring fairness and efficiency pose challenges in the tolling space

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    
                        <strong>Cloud BOS:</strong> Our cloud-based Back Office System (BOS) offers a scalable and flexible solution for toll management, enabling seamless interoperability between different tolling agencies and systems.

                        <br/><br/><strong>RUC/MBUF Solutions:</strong> We provide innovative solutions for Road Usage Charging (RUC) and Mileage-Based User Fees (MBUF), leveraging advanced technologies to accurately calculate and collect tolls based on vehicle usage.

                        <br/><br/>By partnering with alpha2x, tolling agencies can overcome these challenges and embrace future-proof solutions that optimize operational complexity, reduce operational expenses, and provide a seamless experience for both agencies and road users.
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Tolling/illust.jpg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px", mixBlendMode: "darken"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;