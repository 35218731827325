import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Ruc/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Tolling/tollbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461",backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);"  }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">RUC/MBUF </Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Embrace a fair and efficient road funding system through Road Usage Charging (RUC) and Mileage-Based User Fees (MBUF) that accurately charge based on road usage and vehicle mileage. Promote sustainability and equitable funding for transportation infrastructure.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/RUC/ruc.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <ul className="arrow-bullet">
                        <li>Traditional fuel taxes are becoming inadequate as vehicles become more fuel-efficient and electric.</li>
                        <li>Inequitable road funding systems based on fixed fuel taxes fail to account for varying road usage patterns and vehicle types.</li>
                        <li>Insufficient funding for infrastructure maintenance and expansion due to limitations in revenue collection.</li>
                        <li>Industry data reveals that fuel tax revenue has declined by 30% in the past decade.</li>
                    </ul>
                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <ul className="arrow-bullet">
                        <li>Adopt a progressive Road Usage Charging (RUC) and Mileage-Based User Fees (MBUF) system.</li>
                        <li>Utilize advanced technologies like GPS tracking and telematics to accurately capture road usage data.</li>
                        <li>Enable dynamic pricing models that incentivize efficient travel behavior and promote sustainable transportation.</li>
                        <li>Ensure fair and equitable distribution of road usage charges based on actual mileage and usage patterns.</li>
                        <li>Support infrastructure funding with a transparent and sustainable road funding system.</li>
                        <li>Leverage advanced data analytics to identify revenue optimization opportunities and improve funding allocation.</li>
                        </ul>
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/RUC/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;