import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/UPF/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/UPF/upfbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />

            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Unified Payment Platform</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Empower secure and seamless payment experiences with our unified payment solutions. We provide robust payment gateway integrations that streamline transactions, enhance customer trust, and drive business growth.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/UPF/upf1.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Fragmented payment systems result in complex integration processes and inconsistent user experiences.</li>
                            <li>Security vulnerabilities and fraud risks pose threats to payment transactions and customer data.</li>
                            <li>Limited payment options and lack of interoperability hinder customer convenience and satisfaction.</li>
                            <li>According to industry reports, 50% of online shoppers abandon their purchases due to a lack of seamless payment experiences.</li>
                        </ul>

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Integrate diverse payment methods into a unified platform, ensuring a seamless payment experience for customers.</li>
                            <li>Our advanced encryption technologies and robust fraud detection mechanisms enhance payment security.</li>
                            <li>Secure tokenization and data protection to safeguard sensitive customer information.</li>
                            <li>Foster collaboration with financial institutions and industry partners to establish interoperability and expand payment options.</li>
                            <li>Leverage advanced analytics to gain insights into customer preferences and behavior for personalized payment experiences.</li>
                            <li>Enhance customer trust and loyalty through smooth and secure payment transactions.</li>
                        </ul>

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/UPF/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;