import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Serve/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Rental/rentalbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack padding={{xs:"20px",sm:"40px"}} paddingTop={{xs:"40px",sm:"80px"}} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Rental/rental.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Rental car Companies</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        Juggling multiple toll accounts, ensuring accounts stay
                        current to prevent violations and coordinating toll
                        equipment among drivers can easily consume a fleet
                        manager’s workday and cost the fleet significant time and
                        money.
                        <br />
                        Our technology-enabled, flexible toll management and toll
                        violation elimination solutions answer these challenges by
                        consolidating fleet tolling into centralized, automated
                        solutions that can either act as the fleet’s primary tolling
                        method, or as a back-up to prevent toll violations.
                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{xs:"20px",sm:"40px"}} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Optimizing RCC management</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        As the leading provider of toll management solutions
                        throughout North America, and toll and violation collection
                        solutions throughout Europe, we can:
                    </Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Toll Violations:</strong> Reduce or eliminate on covered all-
                        electronic and cashless toll lanes

                        <br></br><strong> Fleet Toll Spend:</strong> Gain crucial visibility into tolling activity

                        <br></br><strong>Toll Processing and Administration:</strong>  Save 50-80 percent
                        over traditional methods

                        <br></br><strong>Interoperable Tolling:</strong>  Available on all-electronic and
                        cashless toll roads for all covered vehicles
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Rental/illus.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;