import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/SPS/Browse';
const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/SPS/spsbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />

            <div id="cont"></div>
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-50px", sm: "-70px", md: "-120px", lg: "-150px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Smart Parking Solutions</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                    Embrace smart parking solutions that leverage technology to optimize parking operations, improve traffic flow, and enhance the overall parking experience.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/SPS/sps.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    
                    <strong>Inefficient parking space management:</strong> Lack of real-time parking availability information and poor utilization of parking spaces contribute to congestion.<br/>
                    <strong>Parking guidance:</strong> Insufficient guidance systems result in drivers spending excessive time searching for available parking spaces.<br/>
                    <strong>Sustainability and environmental impact:</strong> Inefficient parking operations contribute to increased traffic congestion, emissions, and energy consumption.<br/>
Studies indicate that smart parking solutions can reduce traffic congestion by up to 30% and decrease parking search time by 50%.


           

                </Typography>
            </Stack>

        </Stack >
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <strong>Real-time parking occupancy monitoring:</strong> Utilize IoT sensors and data analytics to provide real-time parking availability information to drivers.<br/>
                    <strong>Parking guidance systems:</strong> Implement smart signage, mobile apps, and dynamic displays to guide drivers to available parking spaces.<br/>
                    <strong>Intelligent parking reservation:</strong> Enable drivers to reserve parking spaces in advance, ensuring guaranteed parking upon arrival.<br/>
                    <strong>Integration with navigation systems:</strong> Collaborate with mapping and navigation providers to incorporate parking availability data into their platforms.<br/>
                    <strong>Sustainability initiatives:</strong> Introduce electric vehicle (EV) charging infrastructure, prioritize parking for low-emission vehicles, and promote shared mobility options.


                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/SPS/illus.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;