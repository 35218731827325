import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/PAP/Browse';
const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/PAP/papb.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />

            <div id="cont"></div>
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-50px", sm: "-70px", md: "-120px", lg: "-150px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Parking Access and Payment</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                    Simplify parking access and payment processes with our integrated solutions. We offer user-friendly interfaces and multiple payment options to enhance convenience and streamline parking transactions.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/PAP/pap.webp"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    
                   <strong>Complex access control systems:</strong> Inconsistent methods for granting access to parking facilities can result in user frustration and delays.<br/>
                   <strong>Limited payment options:</strong> Inadequate payment methods and lack of flexibility in parking transactions inconvenience users and limit revenue opportunities.<br/>
                   <strong>Manual payment processes:</strong> Cash-based systems and manual ticketing processes slow down the parking payment process and increase operational costs.<br/>
According to industry data, 75% of drivers experience difficulties with parking payment methods.

           

                </Typography>
            </Stack>

        </Stack >
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                   <strong>Seamless access control:</strong>  Implement integrated access control systems that enable smooth entry and exit processes for parking facilities.<br/>
                   <strong>Diverse payment options:</strong>  Offer a range of payment methods, including mobile payments, contactless cards, and digital wallets, for user convenience.<br/>
                   <strong>Automatic payment systems:</strong>  Integrate automated payment solutions, such as license plate recognition and mobile apps, to eliminate manual ticketing and cash handling.<br/>
                   <strong>Parking loyalty programs:</strong>  Implement loyalty programs and rewards systems to incentivize frequent parkers and enhance user engagement.<br/>
                   <strong>Streamlined administration:</strong>  Provide centralized administration and reporting tools for parking operators to manage payments, revenue, and user data effectively.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/UPS/illus.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;