import { Stack, Typography } from "@mui/material";

const About = () => {

    return (
        <>

            <Stack justifyContent="center" alignItems={"center"} padding={{ xs: "20px", sm: "40px" }}>
                <Typography sx={{ fontWeight: "700", fontSize: { xs: "30px", sm: "35px", lg: "40px" }, color: "#3d3d3d", marginBottom: "10px" }}>Privacy Policy for <span style={{ backgroundImage: "linear", color: "rgb(132 80 189)" }}>Alpha2x </span></Typography>
                <Typography  padding={{ sm: "30px", md: "20px 120px 30px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>
                    At Alpha2x.com, accessible from Alpha2x.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Alpha2x.com and how we use it.<br /><br/>
                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us on the email address hello@alpha2x.com <br /><br/>
                    This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Alpha2x.com. This policy is not applicable to any information collected offline or via channels other than this website.<br />


                </Typography>
                <Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }}>Consent</Typography>
                <Typography  padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.</Typography>

                <Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Information we collect</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>We collect information that you provide to us directly, including contact information, such as first name, last name, e-mail address, phone number, state/region, company name or website name, as well as other information that could be on your business card (such as your company address and phone number) when you visit our website or talk to us at a conference or other event, or if you are a customer. We use the information you provide to deliver our products and services, to send you some privacy and/or security content that we make available on our website and that you requested, or to get in touch with you about a demo/trial. We also use it to support our marketing efforts, including contacting you to provide additional information on our products and services. For customers, we use it to set up your customer account.<br/><br/>
If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
</Typography>
<Typography sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >How we use your information</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>We use the information we collect in various ways, including to:<br/>
                <ul className="arrow-bullet">
                <li>Provide, operate, and maintain our website </li>
                <li>Improve, personalize, and expand our website </li>
                <li>Understand and analyze how you use our website </li>
                <li>Develop new products, services, features, and functionality </li>
                <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes </li>
                <li>Send you emails </li>
                <li>Find and prevent fraud </li>

                   
                    </ul>
                
</Typography>
<Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Log Files</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>Alpha2x.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
</Typography>
<Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Cookies</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>Alpha2x.com does not use 'cookies'. However it may use cookies in future to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
</Typography>
<Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Advertising Partners Privacy Policies</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>You may consult this list to find the Privacy Policy for each of the advertising partners of Alpha2x.com.<br/><br/>
Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Alpha2x.com, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.<br/><br/>
Note that Alpha2x.com has no access to or control over these cookies that are used by third-party advertisers.

</Typography>
<Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Third Party Privacy Policies</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>Alpha2x.com's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.<br/><br/>
You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.


</Typography>
<Typography  sx={{ fontWeight: "700", marginTop: "20px", fontSize: { xs: "26px", sm: "28px", md: "30px" }, color: "#3d3d3d" }} >Children's Information</Typography>
                <Typography   padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.<br/><br/>
Alpha2x.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.



</Typography>

            </Stack>
        </>
    );
}

export default About;