import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/UPS/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Parking/park-banner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />

            <div id="cont"></div>
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-50px", sm: "-70px", md: "-120px", lg: "-150px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Unified Parking Solutions</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Experience a unified parking ecosystem with our advanced parking solutions. We offer seamless integration across vendors, partners, and original equipment manufacturers (OEMs), providing a streamlined parking experience for users.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/UPS/ups.png"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                  
                    <strong>Fragmented parking systems:</strong> Inconsistent parking processes across different vendors and partners lead to user confusion and inefficiencies.<br/>
                        <strong>Lack of interoperability:</strong> Incompatibility between parking systems and equipment hinders seamless integration and cross-platform functionality.<br/>
                        <strong>Limited visibility and control:</strong> Inadequate data insights and management make it challenging to optimize parking operations and enhance user experiences.<br/>
                        Industry statistics reveal that up to 30% of urban traffic congestion is caused by vehicles searching for parking spaces.

                </Typography>
            </Stack>

        </Stack >
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <strong>Unified parking ecosystem:</strong> Integrate parking solutions across various vendors, partners, and OEMs for a cohesive parking experience.<br/>
                    <strong>Interoperable infrastructure:</strong> Implement standardized protocols and technologies to enable seamless communication between parking systems and equipment.<br/>
                    <strong>Real-time data analytics:</strong> Leverage advanced analytics to gain actionable insights and optimize parking operations for improved efficiency.<br/>
                    <strong>Smart parking management:</strong> Utilize IoT sensors, AI-based algorithms, and real-time occupancy data to optimize parking space utilization and reduce congestion.<br/>
                    <strong>Enhanced user experiences:</strong> Provide a user-friendly interface, mobile payment options, and digital parking passes for convenient and hassle-free parking.<br/>
                    <strong>Collaborative partnerships:</strong> Foster collaboration among parking stakeholders to create a unified parking ecosystem and drive innovation.
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/UPS/illus.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;