import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Cloud/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Cloud/cloudbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Cloud BOS</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Transform tolling operations with our cloud-based Back Office System (BOS) that enables seamless interoperability, standardized processes, and real-time data management. Gain actionable insights to optimize tolling revenue and enhance the overall user experience.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Cloud/cloud1.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <ul className="arrow-bullet">
                            <li>Inefficient tolling systems result in revenue leakage and operational inefficiencies.</li>
                            <li>Fragmented tolling processes and lack of interoperability cause inconsistent user experiences.</li>
                            <li>Limited data insights hinder decision-making and optimization of tolling operations.</li>
                            <li>According to industry statistics, up to 10% of potential toll revenue is lost due to inefficiencies.</li>
                    </ul>

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Streamline tolling operations with our cloud-based Back Office System (BOS).</li>
                            <li>Enable seamless interoperability and standardized processes across tolling agencies.</li>
                            <li>Leverage advanced analytics and AI-powered insights for data-driven decision-making.</li>
                            <li>Enhance tolling revenue and improve user experiences through optimized toll collection systems.</li>
                            <li> Implement advanced data management techniques to unlock valuable insights and drive operational efficiencies.</li>
                            <li> Enable real-time monitoring and adaptive toll pricing strategies for improved traffic flow.
                            </li>
                        </ul>


                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Cloud/illus.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;