import { Stack, Typography, Button } from "@mui/material";
import { useState } from "react";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';

const ImageStack = ({ images, titles, desc }) => {
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    return (
        <Stack spacing={4} flexDirection="row" flexWrap="wrap" justifyContent={"center"} gap="40px">
            {images.map((src, index) => (
                <Stack
                    key={index}
                    style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "20px",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <Stack
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <img src={src} style={{ objectFit: "cover", height: "220px", width: "325px" }} alt="pic" />
                        <Stack
                            style={{
                                position: "absolute",
                                backgroundColor: "#3d3d3d",
                                borderBottom: "5px solid rgb(132 80 189)",
                                color: "#fff",

                                padding: "5px",
                                fontWeight: "bold",
                                
                                display: hoverIndex === index ? "none" : "block",

                            }}
                        >
                            <Typography sx={{ padding: "5px", fontSize: "14px", textTransform: "uppercase", fontWeight: "600" }}>{titles[index]}</Typography>
                        </Stack>
                        <Stack flexDirection={"column"}
                            style={{
                                position: "absolute",
                                bottom: 0,
                                left: hoverIndex === index ? "0" : "-100%",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.7)",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                transition: "left 0.3s ease-in-out",
                                zIndex: 1,
                            }}
                        >
                            <Typography variant="body1" sx={{
                                padding: "25px",fontSize:"18px",
                                transition: ".2s ease-in .5s"
                            }}>{desc[index]}</Typography>
                           <Link to={`/${titles[index]}`} style={{textDecoration:"none"}}><Button sx={{ color: "#fff",border:"2px solid rgb(132 80 189)",textTransform:"capitalize"}}>Learn More <ArrowOutwardIcon sx={{fontSize:"20px",marginLeft:"5px"}}/></Button></Link> 
                        </Stack>
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};

const Browse = () => {
    const imageSet1 = ["./images/Serve/serve6.jpg", "./images/Serve/serve4.webp", "./images/Tolling/toll.jpeg"];
    const titlesSet1 = ["Fleet", "Rental Car Companies", "Tolling Agencies"];
    const DescSet1 = [
        "We partner with local governments to create smart and safe transportation.", 
        "Our solutions build safer school zones and reduce illegal school bus passing.",
        "We partner with commercial fleets to solve mobility challenges."
    ];


    return (
        <>
            <Stack padding={{xs:"60px 20px",sm:"80px 40px"}} sx={{ background: "#e5e6eb80" }} justifyContent="center" alignItems="center">
                <Typography color="#3d3d3d" fontSize={{ xs: "30px", md: "35px" }} fontWeight="700" marginBottom="30px" textTransform={"uppercase"} marginLeft={{xs:"0px",md:"30px"}}>
                Who We Serve
                </Typography>
                <ImageStack images={imageSet1} titles={titlesSet1} desc={DescSet1} />
            </Stack>
        </>
    );
};

export default Browse;


