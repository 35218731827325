import { Stack, Box, Typography} from "@mui/material";
// import { useState } from "react";
// import TelegramIcon from '@mui/icons-material/Telegram';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import HubspotContactForm from "../components/HubspotContactForm";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Contact = () => {
    // const [fname, setFname] = useState("");
    // const [lname, setLname] = useState("");
    // const [email, setEmail] = useState("");
    // const [phone, setPhone] = useState("");
    // const [state, setState] = useState("");
    // const [interest, setInterest] = useState("");
    // const [message, setMessage] = useState("");
    // const [agree, setAgree] = useState(false);
    // const regexExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    // const handleOpen = async () => {
    //     if (
    //         !email || !message || !fname
    //     ) {
    //         return toast.error("Please complete the required fields");
    //     }
    //     if (!regexExp.test(email)) {
    //         return toast.warning("Please enter a valid Email");
    //     }
    //     if (agree === false) {
    //         return toast.error("Please accept the privacy policy");
    //     }
    //     toast.success("Message sent successfully!");
    //     setEmail("");
    //     setAgree(false)
    //     setFname("");
    //     setLname("");
    //     setPhone("");
    //     setState("");
    //     setInterest("");
    //     setMessage("");
    // };

    return (
        <>
            <Box
                component="img"
                src="./images/Contact/contactbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            {/* <video width="400" autoPlay muted playsInline loop style={{ width: "100%" }} className="video">
                <source src="./images/Contact/alpha2x.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Stack position="absolute" sx={{ top:{xs:"25vw",md:"15vw"} }} justifyContent="center" alignItems="center" width="100%">
                <Typography color="#f5f5f5" fontSize={{xs:"24px",sm:"35px",md:"50px"}} fontWeight="600" textAlign="center">
                    Innovative Enterprise<br /> Toll Back Office Solutions
                </Typography>
                <Typography color="#f5f5f5a6" fontSize={{xs:"18px",sm:"22px",md:"30px"}} fontWeight="600" textAlign="center">
                    with focus on security, scalability, and performance
                </Typography>
                <a href="#cont"
                ><ExpandMoreIcon sx={{
                    color: "#f5f5f5a6", fontWeight: "bold", fontSize: "40px", minHeight: "50px",
                    minWidth: "50px", border: "1px solid white",
                    borderRadius: " 50%", marginTop:{sm:"40px",md:"80px"},display:{xs:"none",sm:"block"}
                }} /></a>
            </Stack>
            <div id="cont"></div> */}
            <Stack flexDirection={{ xs: "column-reverse", md: "row" }} marginTop={{ xs: "0px", md: "10px", lg: "40px" }} padding={{ xs: "25px", md: "50px" }} justifyContent="space-evenly" gap="40px">
                <Stack flexDirection="column" gap="30px" marginTop={{ md: "13px" }} width={{xs:"100%",md:"38%"}}>
                    <Typography color="#3d3d3d" fontSize="24px" fontWeight="600">Let Us Help You Find a Solution</Typography>
                    <>
                    <HubspotContactForm
                            region="na1"
                            portalId="39908826"
                            formId='8775cfd4-9d77-4df7-8747-4392a440924a'
                        />
                        </>
                </Stack>
                <Stack flexDirection="column" gap="20px" alignItems={{ xs: "center", md: "flex-start" }}>
                    <Typography color="#3d3d3d" fontSize="36px" fontWeight="800">CONTACT US</Typography>
                    {/* <Typography color="#3d3d3d" fontSize="18px">We’d love to hear from you! Use the form here <br />and we will be in touch as soon as possible.</Typography>
                    <Typography color="#3d3d3d" fontSize="18px" fontWeight="600">(123) 456-7894</Typography> */}
                    <Box
                        component="img"
                        src="./images/Contact/contact.png"
                        sx={{
                            width: { xs: "60vw", sm: "40vw", md: "35vw", lg: "30vw" },
                            margin: "10px 0px"

                        }}
                    />
                </Stack>

            </Stack>
            <ToastContainer />
        </>
    );
}

export default Contact;