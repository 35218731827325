import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Parking/Browse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Parking = () => {
    return (
        <>
            {/* <Box
                component="img"
                src="./images/Parking/park-banner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px", md: "250px", lg: "400px" }
                }}
            /> */}
            <video width="400" autoPlay muted playsInline loop style={{ width: "100%" }} className="video" poster="./images/Parking/parkban.jpg">
                <source src="./images/Parking/parkingvideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Stack position="absolute" sx={{ top: { xs: "25vw", md: "15vw" } }} justifyContent="center" alignItems="center" width="100%">
                {/* <Typography color="#f5f5f5" fontSize={{ xs: "24px", sm: "35px", md: "50px" }} fontWeight="600" textAlign="center">
                    Unified Smart Parking Solutions
                </Typography> */}
                <Typography color="#f5f5f5e0" fontSize={{ xs: "18px", sm: "28px", md: "35px" }} fontWeight="600" margin="0px 10px" textAlign="center">
                Empowering Industries<br/>Driving Technological Advancements for a Smarter Tomorrow
                </Typography>
                <a href="#cont"
                ><ExpandMoreIcon sx={{
                    color: "#f5f5f5a6", fontWeight: "bold", fontSize: "40px", minHeight: "50px",
                    minWidth: "50px", border: "1px solid white",
                    borderRadius: " 50%", marginTop: { sm: "40px", md: "80px" }, display: { xs: "none", sm: "block" }
                }} /></a>
            </Stack>
            <div id="cont"></div>
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-50px", sm: "-70px", md: "-120px", lg: "-150px" }}>
                <Stack sx={{ background: "#485461",backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Unified Smart Parking Solutions </Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Experience a unified parking ecosystem with our advanced parking solutions that simplify parking access, payment processes with our integrated solutions. User-friendly interfaces and multiple payment options enhance convenience and streamline parking transactions. We offer seamless integration across vendors, partners, and original equipment manufacturers (OEMs), providing a streamlined parking experience for users. Embrace smart parking solutions that leverage technology to optimize parking operations, improve traffic flow, and enhance the overall parking experience.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Parking/parking.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        Industry statistics reveal that up to 30% of urban traffic congestion is caused by vehicles searching for parking spaces, about 75% of drivers experience difficulties with parking payment methods.<br />
                        <strong>Fragmented parking systems:</strong> Inconsistent parking processes across different vendors and partners lead to user confusion and inefficiencies.<br />
                        <strong>Lack of interoperability:</strong> Incompatibility between parking systems and equipment hinders seamless integration and cross-platform functionality.<br />
                        <strong>Limited visibility and control:</strong> Inadequate data insights and management make it challenging to optimize parking operations and enhance user experiences.<br />
                        <strong>Limited payment options:</strong> Inadequate payment methods and lack of flexibility in parking transactions inconvenience users and limit revenue opportunities.<br />
                        <strong>Manual payment processes:</strong> Cash-based systems and manual ticketing processes slow down the parking payment process and increase operational costs.

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        Studies indicate that smart parking solutions can reduce traffic congestion by up to 30% and decrease parking search time by 50%.<br />
                        <strong>Unified parking ecosystem fostering collaborative partnerships:</strong> Integrate parking solutions across various vendors, parking stakeholders, partners, and OEMs for a cohesive parking experience.<br />
                        <strong>Interoperable infrastructure:</strong> Standardized protocols and technologies enable seamless communication between parking systems and equipment.<br />
                        <strong>Enhanced user experiences:</strong> Provide a user-friendly interface, mobile payment options, digital parking passes, diverse payment options for including mobile payments, contactless cards, and digital wallets with automatic payment systems to eliminate manual ticketing, cash handling and improve user convenience.<br />
                        <strong>Smart Parking Management & Solutions:</strong> Integrate with parking providers that seamlessly provide real-time parking occupancy monitoring utilizing AI-based algorithms and IoT Sensors, Parking guidance systems that display available spaces for drivers, Intelligent parking reservation for advanced reservations and support sustainability initiatives that prioritizes low-emission vehicles like EVs and promote shared mobility options.<br />
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Parking/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;