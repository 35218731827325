import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Ewallet/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Ewallet/ewbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">E-Wallets</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Embrace the convenience and flexibility of electronic wallets (eWallets) to enable quick and secure digital payments. Our eWallet solutions offer a seamless and contactless payment experience for customers.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Ewallet/ew.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Fragmented eWallet solutions create a lack of interoperability and hinder widespread adoption.</li>
                            <li>Security concerns and privacy issues deter customers from embracing eWallet technologies.</li>
                            <li>Limited merchant acceptance and integration pose barriers to the adoption of eWallets.</li>
                            <li>Industry data shows that eWallet transactions are expected to grow by 150% in the next five years.</li>
                        </ul>

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Support multiple payment methods and integrate with various merchants at ease & with simplified APIs & SDKs.</li>
                            <li>Advanced security measures, including multi-factor authentication and tokenization, to ensure secure transactions.</li>
                            <li>Collaborate with industry partners and merchants to expand eWallet acceptance and promote widespread adoption.</li>
                            <li>Offer personalized loyalty programs and rewards within the eWallet ecosystem to enhance customer engagement.</li>
                            <li>Leverage cutting-edge technologies such as biometrics and near-field communication (NFC) for seamless and contactless payments.</li>
                            <li>Enable real-time transaction tracking and reporting for enhanced transparency and control.</li>
                        </ul>

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Ewallet/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;