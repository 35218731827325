import { Stack, Typography } from "@mui/material";
import { useState } from "react";
// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
// import { Link } from 'react-router-dom';


const ImageStack = ({ images, titles, desc }) => {
    const [hoverIndex, setHoverIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    return (
        
        <Stack spacing={4} flexDirection="row" flexWrap="wrap" justifyContent={"center"} gap="35px">
            {images.map((src, index) => (
                <div
                    key={index}
                    style={{
                        position: "relative",
                        cursor: "pointer",
                        marginTop: "20px",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <img src={src} style={{ objectFit: "cover", height: "220px", width: "325px" }} alt="pic" />
                        <div
                            style={{
                                position: "absolute",
                                backgroundColor: "#3d3d3d",
                                borderBottom: "5px solid rgb(132 80 189)",
                                color:"#fff",
                              
                                padding: "5px",
                                fontWeight: "bold",
                                
                                display: hoverIndex === index ? "none" : "block",
                                
                            }}
                        >
                            <Typography sx={{padding:"5px",fontSize:"14px",textTransform:"uppercase",fontWeight:"600"}}>{titles[index]}</Typography>
                        </div>
                        <Stack flexDirection={"column"}
                            style={{
                                position: "absolute",
                                bottom: 0,
                                left: hoverIndex === index ? "0" : "-100%",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.7)",
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                transition: "left 0.3s ease-in-out",
                                zIndex: 1,
                            }}
                        >
                            <Typography variant="body1" sx={{
                                padding: "25px",fontSize:"18px",
                                transition: ".2s ease-in .5s"
                            }}>{desc[index]}</Typography>
                           {/* <Link to={`/${titles[index]}`} style={{textDecoration:"none"}}>
                            <Button sx={{ color: "#fff",border:"2px solid rgb(132 80 189)",textTransform:"capitalize"}}>Learn More <ArrowOutwardIcon sx={{fontSize:"20px",marginLeft:"5px"}}/></Button></Link>  */}
                        </Stack>
                    </div>
                </div>
            ))}
        </Stack>
    );
};

const Serve = () => {
    const imageSet1 = ["./images/Tolling/toll.jpeg","./images/Serve/serve6.jpg", "./images/Serve/serve4.webp"];
    const titlesSet1 = ["Government Agencies","Fleet", "Rental Car Companies"];
    const DescSet1 = [
        "We partner with local governments to provide smart tolling systems.",
        "We collaborate with fleet operators to provide seamless tolling solutions and enhance operational efficiency.", 
        "We empower rental car companies with integrated tolling and payment solutions, ensuring a seamless and convenient payment experience for their customers."
    ];

    return (
        <> 
            <Stack padding={{ xs:"40px", md:"50px 60px", lg:"80px 100px" }} sx={{background:"#e5e6eb80"}}>
                <Typography  color="#3d3d3d" fontSize={{xs:"30px",md:"35px"}} fontWeight="700" marginBottom="10px" textAlign="center">
                    WHO WE SERVE
                </Typography>
                <Typography sx={{ fontSize:{xs:"18",md:"20px"}, fontWeight: "500", paddingBottom: "15px",color:"#3d3d3d" }}>
                Interconnected world, evolving industries, complex technologies need smarter, adaptive solutions to make mobility secure, seamless and faster !
                </Typography>
                
                <ImageStack images={imageSet1} titles={titlesSet1} desc={DescSet1} />
            </Stack>
        
        </>
    );
};

export default Serve;


