import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme/theme';
import Layout from './components/Layout';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
     <BrowserRouter>
    <Layout>
      <CssBaseline />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Layout>
    </BrowserRouter>
  </ThemeProvider>
);
