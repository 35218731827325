import React,{ useEffect } from 'react';
import {  Routes, Route ,useLocation} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Parking from './pages/Parking';
import Tolling from './pages/Tolling';
import PaymentG from './pages/PaymentG';
import Telecom from './pages/Telecom';
import Techno from './pages/Technology';
import Cloud from './pages/Cloud';
import Ruc from "./pages/Ruc";
import Dynamic from './pages/Dynamic';
import UPF from "./pages/Upf";
import Ewallet from "./pages/Ewallet";
import Fleet from "./pages/Fleet";
import Rental from "./pages/Rental";
import TollingAgencies from "./pages/TollingAgencies";
import About from "./pages/About";
import Privacy from "./pages/Privacy"
import UPS from "./pages/UPS"
import PAP from "./pages/Pap"
import SPS from "./pages/Sps"
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

const App = () => {
  return (
    <>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/parking" element={<Parking />} />
        <Route path="/tolling" element={<Tolling />} />
        <Route path="/payment gateways" element={<PaymentG />} />
        <Route path="/telecommunication" element={<Telecom />} />
        <Route path="/technology stratergy" element={<Techno />} />
        <Route path="/cloud bos" element={<Cloud />} />
        <Route path="/ruc" element={<Ruc />} />
        <Route path="/dynamic pricing" element={<Dynamic />} />
        <Route path="/unified payment solutions" element={<UPF />} />
        <Route path="/Ewallets" element={<Ewallet />} />
        <Route path="/Fleet" element={<Fleet />} />
        <Route path="/Rental Car Companies" element={<Rental />} />
        <Route path="/Tolling Agencies" element={<TollingAgencies />} />
        <Route path="/About" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/Unified Parking Solutions" element={<UPS />} />
        <Route path="/Parking Access and Payment" element={<PAP />} />
        <Route path="/Smart Parking Solutions" element={<SPS />} />
      </Routes>
      </>
  );
};

export default App;
