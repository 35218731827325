import { Stack, Typography } from "@mui/material";
// import Leaders from "../components/About/Leaders";

const About = () => {

    return (
        <>

            <Stack justifyContent="center" alignItems={"center"} padding={{ xs: "20px", sm: "40px" }}>
                <Typography textAlign={"center"} sx={{ fontWeight: "700", fontSize: { xs: "30px", sm: "35px", lg: "40px" }, color: "#3d3d3d", marginBottom: "10px" }}>Team that Engineers your  <span style={{ backgroundImage: "linear", color: "rgb(132 80 189)" }}>NextGen Solutions</span></Typography>
                <Typography variant="h6" textAlign={"center"} padding={{ sm: "50px", md: "20px 120px 50px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>
                    Alpha2x is a software, consulting and high-value services provider for the transportation, mobility, telecommunications, financial services industries. We specialize in providing software-first solutions that facilitate timely and accurate execution. <br /><br />
                    Our core values are customer obsession, exceeding expectations, professionalism, and accountability.<br /><br />
                    Our core value propositions include new and diversified revenue streams, reduced total cost of ownership, and the enablement of partnerships. We provide high-value services in management consulting, system integration, application development and maintenance, and program governance. We focus on well-defined projects with clearly articulated value propositions and outcomes.<br /><br />
                    We are proficient in cloud computing, application development, cloud migration, full-stack development, big data and analytics, executive strategy, and cloud migration. <br /><br />
                    At Alpha2x, we are dedicated to providing software-first solutions that enable our clients to achieve their business objectives.

                </Typography>
                <Typography textAlign={"center"} sx={{ fontWeight: "700", marginTop: "20px",fontSize:{xs:"30px",sm:"35px",md:"40px"},color:"#3d3d3d"}}>Leadership Team</Typography>
                <Typography variant="h6" textAlign={"center"} padding={{ sm: "50px", md: "20px 120px 0px 120px" }} fontSize={{ xs: "16px", sm: "18px", lg: "20px" }}>
                Our founders and managing partners bring decades of experience in the respective fields of engineering, telecom, finance, and technology. They have a proven track record of success and are committed to driving growth for our clients</Typography>
            </Stack>

            {/* <Leaders /> */}
        </>
    );
}

export default About;