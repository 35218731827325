import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Dynamic/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Dynamic/dpbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461",backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Dynamic Pricing </Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Optimize tolling operations and revenue generation through dynamic pricing strategies that adjust toll rates based on real-time data and demand. Improve traffic flow and create a more efficient tolling experience for users.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Dynamic/dp.png"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Fixed toll rates lead to traffic congestion and underutilization of road infrastructure.</li>
                            <li>Lack of flexibility in toll pricing hinders efficient traffic flow management.</li>
                            <li>Inability to adapt to changing traffic conditions and demand patterns limits revenue optimization.</li>
                            <li>Studies indicate that dynamic pricing can reduce traffic congestion by up to 25%.</li>
                        </ul>

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <ul className="arrow-bullet">
                            <li>Utilize dynamic pricing strategies to adjust toll rates in real-time based on traffic and demand.</li>
                            <li>Encourage off-peak travel by implementing variable toll rates that incentivize efficient travel behavior.</li>
                            <li>Optimize tolling revenue and reduce congestion by dynamically adjusting toll rates in response to traffic conditions.</li>
                            <li>Enhance operational flexibility and responsiveness with intelligent rate adjustments based on traffic demand patterns.</li>
                            <li>Improve the overall user experience by promoting smoother traffic flow and reducing travel times.</li>
                            <li>Implement real-time data analytics to continuously optimize tolling operations and revenue generation.</li>
                        </ul>

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Dynamic/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;