import { Stack, Typography} from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';

const Landing = () => {
    return (
        <>
            <Stack flexDirection="column" justifyContent="center" alignItems="center" padding="60px 30px" gap="15px" sx={{background: "linear-gradient(275.08deg, #C7DAFF 13.42%, rgba(199, 218, 255, 0.642907) 30.55%, rgba(199, 218, 255, 0.55) 58.75%, rgba(199, 218, 255, 0.81) 80.33%)",borderRadius:{xs:"0px",md:"0px 0px 200px 200px"}}}>
                <Typography textAlign="center" sx={{ color: "rgba(30, 30, 30, 0.89)", fontSize:{xs:"30px",sm:"35px",md:"40px"}, fontWeight: "700" }}>Innovative, Intelligent, Data Driven, <br />
                Smart Mobility Solutions</Typography>
                <Typography sx={{ fontSize:{xs:"18px",sm:"20px",md:"22px"}, color: "#171718", textAlign: "center" }}>Security, Scalability & Performance to the first order !</Typography>

            </Stack>

        </>);
}

export default Landing;