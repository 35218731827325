import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Telecom/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Telecom/telecombanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Telecommunications</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Leverage our extensive expertise in the telecommunications industry to drive innovation and transformation in your organization. With deep roots and connections in the Telco space, we offer strategic direction in areas such as negotiations on 5G contracts, comodatizing road infrastructure using Telco equipment, and creating a data information superhighway. Our executive team brings the needed expertise to propel your telecommunications initiatives to new heights.
                    </Typography>
                </Stack>
            </Stack>
            <Stack flexWrap={"wrap"} padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "20px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center" alignItems={"center"}>
                    <Box
                        component="img"
                        src="./images/Telecom/1.svg"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px"
    
                        }}
                    />
                </Stack>
                <Stack justifyContent="center"  alignItems={"center"} width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Expertise in 5G Contract Negotiations</Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Strategic Contract Negotiations:</strong> Tap into our experience and negotiation skills to navigate the complexities of 5G contracts. We provide strategic guidance to ensure favorable terms, optimal pricing, and enhanced service agreements that meet your business objectives.<br></br>
                        <strong>Vendor Selection and Management:</strong> Benefit from our expertise in selecting and managing telecom vendors, ensuring seamless integration, efficient deployment, and ongoing support for your 5G infrastructure.
                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap={{ xs: "20px", md: "40px" }}>
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Leverage our Deep Roots and Connections in the Telco Space</Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Industry Relationships: </strong> Leverage our extensive network of industry connections to forge strategic partnerships with telecom providers, infrastructure companies, and other stakeholders. These relationships enable access to cutting-edge technologies and resources.

                        <br></br><strong> Industry Insights and Trends:</strong> Stay ahead of the curve with our in-depth knowledge of telecommunications trends, regulations, and market dynamics. We provide valuable insights to inform your decision-making and help you seize new opportunities.
                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Telecom/2.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Stack flexWrap={"wrap"}  alignItems={"center"} padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "10px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Telecom/3.svg"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px"
    
                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Commoditizing Road Infrastructure using Telco Equipment
</Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Strategic Road Infrastructure Planning:</strong> Collaborate with our team to develop a strategic roadmap for comodatizing road infrastructure. We leverage Telco equipment and technologies to optimize road management, enhance connectivity, and improve traffic flow.
<br></br>
                        <strong>Collaborative Partnerships: </strong> Facilitate partnerships between telecom companies and transportation authorities to create a win-win scenario, maximizing the utilization of Telco equipment for both telecommunication and road infrastructure purposes.
                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap={{ xs: "10px", md: "40px" }}>
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Creating a Data Information Superhighway
</Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Data Infrastructure Planning: </strong> Tap into our expertise in designing and implementing data infrastructure solutions. We help create a robust data information superhighway that enables seamless data transfer, storage, and processing for your telecommunications operations.
                        <br></br><strong> Data Governance and Security:</strong> Implement robust data governance and security measures to safeguard your critical data assets. We ensure compliance with regulations and industry standards while optimizing data utilization for actionable insights.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Telecom/4.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Stack  alignItems={"center"} flexWrap={"wrap"} padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "10px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "10px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Telecom/5.svg"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px"
    
                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Executive Expertise
</Typography>
                    <Typography sx={{ marginLeft: "30px", marginTop: "10px" }} color="#444444" fontSize={{ xs: "16px", md: "18px" }}>

                        <strong>Strategic Direction:</strong>  Engage our executive team to provide strategic direction for your telecommunications initiatives. We offer insights and guidance based on our extensive industry experience and expertise.
<br></br>
                        <strong>Industry Best Practices:</strong> Leverage the knowledge and best practices shared by our executives to optimize your telecommunications operations and drive innovation.<br></br>
                        <strong>Data Information Superhighway: </strong>  Benefit from the expertise of our executives in creating a data information superhighway, enabling seamless data transfer, advanced analytics, and data-driven decision-making.
<br></br><br></br>
Unlock the full potential of your telecommunications operations with our strategic guidance, expertise in 5G contract negotiations, and deep connections in the Telco space. Contact us to embark on a transformative journey in the telecommunications industry.

                    </Typography>
                </Stack>

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;