import { Stack, Box, Typography,IconButton } from "@mui/material";
import DevicesIcon from '@mui/icons-material/Devices';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SecurityIcon from '@mui/icons-material/Security';
import Groups2Icon from '@mui/icons-material/Groups2';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import InsightsIcon from '@mui/icons-material/Insights';

const About = () => {
    const Abouts = [
        {
            name: "Cutting-edge Technology",
            icon: DevicesIcon,
            description: "Leverage our cutting-edge cloud based solutions to power scalable and high-performance use cases.",
        },
        {
            name: "Seamless Integration",
            icon: IntegrationInstructionsIcon,
            description: "Standardized and interoperable APIs, ensure smooth data exchange, process flow and seamless integration that power your existing flows.",
        },
        {
            name: "Enhanced Security",
            icon: SecurityIcon,
            description: "Data protection using advanced encryption techniques safeguarding against unauthorized access and ensuring confidentiality.",
        },
        {
            name: "Customizable Solutions to Fit Your Needs",
            icon: Groups2Icon,
            description: "Tailor our solutions to your specific requirements and preferences, ensuring a perfect fit for your business operations.",
        },
        {
            name: "Scalable Architecture for Future Growth",
            icon: TravelExploreIcon,
            description: "Scale effortlessly as your business grows!  Thanks to our scalable architecture that accommodates increasing demands without compromising performance.",
        },
        {
            name: "Data-driven Insights for Informed Decision-making",
            icon: InsightsIcon,
            description: "Drive informed decision-making and optimize operations by harnessing the power of your data with deep insights!",
        },

    ];
    return (
        <>
            <Stack flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent="space-around" alignItems="center" padding="60px 40px" gap="40px">
                <Box
                    component="img"
                    src="./images/Home/home.jpeg"
                    sx={{
                        width: { md: "60vw", lg: "68vw" },
                        height: { md: "30vw", lg: "31vw" },
                        borderRadius: "8px",
                        display:{xs:"none",md:"block"}
                    }}
                /> 
                <Stack flexDirection="column" gap="20px">
                    <Typography sx={{ color: "#3d3d3d", fontSize:{xs:"30px",md:"35px"}, fontWeight: "700", marginBottom:{xs:"10px",md:"15px"} }}>Accelerated Impact through Technology</Typography>

                    {Abouts.map(about => (
                        <Stack flexDirection="column" >
                            <Stack flexDirection="row" gap="15px" justifyContent="flex-start" alignItems="center">
                                <Stack flexDirection="row"> 
                                <IconButton
                                    sx={{         
                                        color: "#105ED5",
                                        width: "60px",
                                        height: "60px",
                                     
                                        
                                    }}
                                >
                                    <about.icon sx={{fontSize:"32px"}}/>
                                </IconButton>
                                </Stack>
                                <Stack flexDirection="column">
                                    <Typography color="#3d3d3d" fontWeight="600" fontSize={{ xs: "18px", sm: "20px", md: "22" }} >{about.name}</Typography>
                                    <Typography color="#3d3d3d" fontSize={{ xs: "16px", sm: "18px", md: "20" }} >{about.description}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}


                </Stack>
            </Stack>

        </>

    );
}

export default About;