import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/Parking/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Technology/techbanner.png"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461", backgroundImage: "linear-gradient(109.6deg, rgb(24 39 66) 11.2%, rgb(36, 59, 85) 91.1%);" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Strategic Technology</Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Unlock the power of cost-efficient, scalable, and performant cloud-based systems and solutions with our comprehensive technology strategy services. We specialize in cloud migration, data management, leveraging artificial intelligence (AI) and machine learning (ML) models, and providing executive-level expertise as a service to drive innovation and business success.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "20px", md: "40px" }} >
                <Stack justifyContent="center" alignItems="center">
                    <Box
                        component="img"
                        src="./images/Cloud/cloud1.jpg"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Cloud-Based Systems and Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Cost-Efficient and Scalable Infrastructure:</strong> Harness the capabilities of cloud computing to optimize costs and scale your infrastructure based on demand, ensuring efficient resource utilization.<br />
                        <strong>Performant Applications:</strong> Develop and deploy high-performance cloud-based applications that deliver superior user experiences and meet your business requirements.<br />
                        <strong>Flexibility and Agility:</strong> Leverage cloud technologies to quickly adapt to changing market dynamics and business needs, enabling faster time-to-market for new products and services.<br />
                        <strong>Resilience and Security:</strong> Implement robust cloud security measures, disaster recovery mechanisms, and data backup strategies to safeguard your business-critical applications and data.

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap={{xs:"10px",sm:"40px"}}>
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Cloud Migration</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Comprehensive Cloud Migration Strategy:</strong> Define a tailored cloud migration roadmap that aligns with your business goals and ensures a smooth transition of your applications and data to the cloud.<br />
                        <strong>Application Modernization:</strong> Evaluate existing applications and modernize them by adopting cloud-native architectures, microservices, and containerization to optimize performance and scalability.<br />
                        <strong>Data Migration and Integration:</strong> Plan and execute the migration of your data to the cloud, ensuring seamless integration and minimal downtime for your business operations.<br />
                        <strong>Risk Mitigation:</strong> Identify and mitigate potential risks associated with cloud migration, such as data loss, compatibility issues, and security vulnerabilities, through meticulous planning and execution.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Cloud/illus.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
            <Stack justifyContent="center" alignItems="center">
                    <Box
                        component="img"
                        src="./images/Technology/illus1.png"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
            

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Data Management, Data Governance, and Data Quality</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Data Lakes and Data Meshes:</strong> Implement robust data lake architectures and data mesh frameworks to enable efficient data storage, processing, and access. Leverage data lakes to integrate and analyze vast amounts of structured and unstructured data.<br />
                        <strong>Data Migration and Integration:</strong> Seamlessly migrate and integrate data from disparate sources, ensuring data integrity, consistency, and adherence to data governance policies.<br />
                        <strong>Data Governance:</strong> Establish data governance frameworks, policies, and practices to ensure data privacy, security, compliance, and quality across the organization.<br />
                        <strong>Data Quality:</strong> Utilize data quality management techniques, including data profiling, cleansing, and enrichment, to enhance the accuracy, completeness, and reliability of your data assets.


                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap={{xs:"10px",sm:"40px"}}>
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>AI and ML Models</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <strong>AI and ML-Driven Insights:</strong> Leverage advanced AI and ML models to analyze data, extract meaningful insights, and make data-driven decisions for your business.<br/>
                    <strong>Model Training and Deployment:</strong> Develop and train custom AI and ML models tailored to your specific industry and business requirements. Deploy these models to automate processes, enhance productivity, and enable predictive capabilities.<br/>
                    <strong>Intelligent Automation:</strong> Automate repetitive tasks and business processes using AI-powered automation solutions, freeing up resources and enabling your workforce to focus on higher-value activities.<br/>
                    <strong>Continuous Improvement:</strong> Implement mechanisms to continuously monitor, evaluate, and refine AI and ML models to ensure their accuracy, relevance, and performance over time.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Technology/ai.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
            <Stack justifyContent="center" alignItems="center">
                    <Box
                        component="img"
                        src="./images/Technology/cto.svg"
                        sx={{
                            width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>CTO As a Service</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <strong>Strategic Technology Leadership:</strong> Leverage our experienced Chief Technology Officer (CTO) as a Service to provide strategic guidance and leadership for your technology initiatives.<br/>
                    <strong>Technology Roadmap and Vision:</strong> Collaborate with our CTO to define a comprehensive technology roadmap aligned with your business objectives, enabling you to stay ahead in a rapidly evolving technological landscape.<br/>
                    <strong>Technology Evaluation and Vendor Management:</strong> Benefit from expert technology evaluation and vendor selection to ensure the adoption of the right solutions and maximize the value of your technology investments.<br/>
                    <strong>Technical Team Empowerment:</strong> Our CTO will work closely with your technical teams, providing mentorship, guidance, and technical expertise to drive innovation, improve processes, and enhance overall technical capabilities.



                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap={{xs:"10px",sm:"40px"}}>
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Executive As A Service</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                    <strong>CEO or CFO Expertise:</strong> Gain access to experienced CEOs or CFOs who can provide strategic guidance and executive-level expertise on technology-related matters.<br/>
                    <strong>Fixed-Term Contracts:</strong> Engage our executive team on a fixed-term basis to provide leadership, drive business growth, and make informed decisions for your organization.<br/>
                    <strong>Strategic Planning and Execution:</strong> Leverage the expertise of our executives to develop technology strategies, set goals, and execute plans that align with your overall business objectives.<br/>
                    <strong>Stakeholder Management:</strong> Benefit from our executive team's extensive experience in stakeholder engagement, ensuring effective communication and collaboration across the organization.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Technology/exe.svg"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;