import '../App.css';
import About from '../components/Home/About';
import Carousel from '../components/Home/Carousel';
import Landing from '../components/Home/Landing';
import Serve from '../components/Home/Serve';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  const aboutSectionRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get('section');
    if (section === 'serve') {
      aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.search]);

  return (
    <div className="App">
      <Landing/>
      <Carousel/>
      <About/>
      <section ref={aboutSectionRef} id="serve">
      <Serve/>
      </section>
    </div>
  );
}

export default App;
