import React, { useRef, useEffect } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import { useMediaQuery } from '@mui/material';
import TollIcon from '@mui/icons-material/Toll';
import PaymentIcon from '@mui/icons-material/Payment';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AndroidIcon from '@mui/icons-material/Android';
const Carouselp = () => {
    const Carousels = [
        {
            name: "Parking",
            icon: CarRepairIcon,
            description: "Revolutionizing Parking Solutions for Enhanced User Experience",
        },
        {
            name: "Tolling",
            icon: TollIcon,
            description: "Innovative Tolling Solutions for Streamlined Transportation Systems",
        },
        {
            name: "Payment Gateways",
            icon: PaymentIcon,
            description: "Seamless Payment Gateways for Secure and Convenient Transactions",
        },
        // {
        //     name: "Finance",
        //     icon: AttachMoneyIcon,
        //     description: "Take informed decisions using the insights generated by robust data systems.",
        // },
        // {
        //     name: "Transportation",
        //     icon: EmojiTransportationIcon,
        //     description: "Gain business agility and flexibility by using scalability and data security of the cloud.",
        // },
        {
            name: "Telecommunication",
            icon: LocalPhoneIcon,
            description: "Transforming Connectivity with Future-Ready Telecom Solutions",
        },

        {
            name: "Tech Strategy",
            icon: AndroidIcon,
            description: "Strategic Technology Solutions for Business Growth and Innovation",
        }
    ];
    const isLargerThanMD = useMediaQuery('(min-width:900px)');
    const containerRef = useRef(null);
    const scrollSpeed = 4; // Adjust the scroll speed as needed
    const scrollDirectionRef = useRef(0); // Use a ref to store the scroll direction
    const animationFrameIdRef = useRef(null); // Use a ref to store the animation frame ID
    const delayTimerRef = useRef(null); // Use a ref to store the delay timer

    useEffect(() => {
        if (isLargerThanMD) {
            const container = containerRef.current;

            const handleMouseMove = (event) => {
                const newDirection = event.movementX > 0 ? 1 : -1;

                // Clear the delay timer if it exists
                if (delayTimerRef.current) {
                    clearTimeout(delayTimerRef.current);
                }

                // Set the new direction after a delay
                delayTimerRef.current = setTimeout(() => {
                    scrollDirectionRef.current = newDirection;
                    requestScrollAnimation();
                }, 150); // Adjust the delay time as needed
            };

            const handleMouseLeave = () => {
                clearTimeout(delayTimerRef.current);
                scrollDirectionRef.current = 0;
                cancelAnimationFrame(animationFrameIdRef.current);
                animationFrameIdRef.current = null;
            };

            const requestScrollAnimation = () => {
                if (animationFrameIdRef.current === null) {
                    animationFrameIdRef.current = requestAnimationFrame(scrollAnimation);
                }
            };

            const scrollAnimation = () => {
                container.scrollLeft += scrollDirectionRef.current * scrollSpeed;
                animationFrameIdRef.current = requestAnimationFrame(scrollAnimation);
            };

            container.addEventListener('mousemove', handleMouseMove);
            container.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                container.removeEventListener('mousemove', handleMouseMove);
                container.removeEventListener('mouseleave', handleMouseLeave);
                cancelAnimationFrame(animationFrameIdRef.current);
                clearTimeout(delayTimerRef.current);
            };
        }
    }, [isLargerThanMD]); // Include isLargerThanMD in the dependency array


    return (
        <>
            <Stack flexDirection="column" margin="60px 0px 0px 0px" sx={{ background: "#e5e6eb80" }}>
                <Stack padding="60px 0px 0px calc((100vw - 1220px) / 2)">
                    <Typography sx={{ color: "#3d3d3d", fontSize: { xs: "30px", md: "35px" }, fontWeight: "700", marginBottom: "20px", padding: "0px 20px" }}>Engineering Solutions for the next big thing <br/>on mobility, payments, telecommunication</Typography>
                </Stack>
                <Stack className="carousel-list" sx={{ overflow: "hidden", position: "relative", overflowX: "auto" }} ref={containerRef}>
                    <Stack sx={{
                        padding: "40px 0px 60px calc((100vw - 1220px) / 2)",
                        WebkitBoxAlign: "stretch",
                        alignItems: "stretch", flexDirection: "row"
                    }}>
                        {Carousels.map((carousel, index) => (
                            <Stack key={carousel.name} flexDirection="row" gap="15px" sx={{
                                background: "#fff",
                                margin: { xs: "0px 12px", sm: "0px 15px", md: "0px 18px" },
                                minWidth: { xs: "310px", sm: "354px" },
                                minHeight: { lg: "188px" },
                                '&:hover': {
                                    backgroundColor: "rgb(233 219 246)",
                                    boxShadow: " rgba(0, 0, 0, 0.1) 0px 11px 15px -3px, rgba(0, 0, 0, 0.04) 0px 9px 30px 3px, rgba(0, 0, 0, 0.04) 0px 20px 30px 3px",
                                    transition: "all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s",
                                    transform: "translateY(-5px)",
                                    '& .hover-typography': {
                                        transform: "translateY(-5px)"
                                    },
                                    '& .hover-typography1': {
                                        transform: "translateY(-7px)"
                                    },
                                    '& .hover-icon': {
                                        transform: " translateY(-11px)",

                                    }
                                },


                                position: "relative",

                                padding: { xs: "25px 19px", sm: "21px 19px", md: " 30px 18px 22px" },
                                boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 18px 0px, rgba(0, 0, 0, 0.1) 0px 3px 5px -1px",
                                transition: "all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s",
                                ...(index % 2 === 1 && {
                                    top: "30px",
                                })
                            }}>
                                <Stack>
                                    <IconButton
                                        sx={{
                                            color: "#105ED5",
                                            width: "60px",
                                            height: "60px",
                                        }} className="hover-icon"
                                    >
                                        <carousel.icon sx={{ fontSize: "36px" }} />
                                    </IconButton>
                                </Stack>
                                <Stack flexDirection="column" gap="5px">
                                    <Typography color="#3d3d3d" fontSize={{ xs: "18px", md: "19px" }} fontWeight="700" className="hover-typography1" sx={{
                                        transition: "all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s"
                                    }}>
                                        {carousel.name}
                                    </Typography>
                                    <Typography color="#3d3d3d" fontSize={{ xs: "16px", md: "17px" }} className="hover-typography" sx={{
                                        transition: "all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s"
                                    }}>
                                        {carousel.description}
                                    </Typography>
                                </Stack>

                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack >
        </>);
}

export default Carouselp;