import React from "react";

class HubspotContactForm extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          target: "#hubspotForm",
          ...this.props,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div id="hubspotForm" />
      </div>
    );
  }
}

export default HubspotContactForm;
