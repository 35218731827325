import { Stack, Box, Typography } from "@mui/material";
import Browse from '../components/PaymentG/Browse';

const Parking = () => {
    return (
        <>
            <Box
                component="img"
                src="./images/Payment/paymentbanner.jpg"
                sx={{
                    width: "100%",
                    height: { xs: "150px",sm:"250px", md: "350px", lg: "450px" }
                }}
            />
            <Stack width="100%" justifyContent="center" alignItems="center" marginTop={{ xs: "-40px", sm: "-40px", md: "-80px", lg: "-100px" }}>
                <Stack sx={{ background: "#485461",backgroundImage: "linear-gradient(315deg, #485461 0%, #28313bc7 74%)" }} width={{ xs: "95%", sm: "80%", lg: "70%" }} padding={{ xs: "20px", sm: "30px 45px", md: "40px 60px", lg: "60px 80px" }}>
                    <Typography color="#f5f5f5" fontSize={{ xs: "30px", sm: "35px" }} fontWeight="700" textAlign="center" marginBottom="15px">Payment Gateway </Typography>
                    <Typography color="#f5f5f5" textAlign={{ xs: "left", sm: "center" }} fontSize={{ xs: "16px", sm: "18px" }}>
                        Facilitate secure and seamless payment experiences with our advanced payment gateway solutions. Our integrated platform empowers businesses to accept various payment methods, build customer trust, and drive revenue growth.
                    </Typography>
                </Stack>
            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "40px", sm: "80px" }} flexDirection={{ xs: "column", md: "row" }} justifyContent="space-evenly" width="100%" alignSelf="center" gap={{ xs: "0px", md: "40px" }} >
                <Stack justifyContent="center">
                    <Box
                        component="img"
                        src="./images/Payment/pg1.jpg"
                        sx={{
                            width: { sm: "40vw", md: "40vw", lg: "30vw" },
                            margin: "10px 0px",
                            display: { xs: "none", md: "block" }

                        }}
                    />
                </Stack>
                <Stack justifyContent="center" width={{ xs: "100%", md: "50vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Challenges</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Fragmented payment systems:</strong> Integrating multiple payment gateways can be complex and result in inconsistent user experiences.<br />
                        <strong>Security vulnerabilities:</strong> Ensuring secure transactions and protecting customer data from fraud risks is paramount.<br />
                        <strong>Limited payment options:</strong> Lack of interoperability and limited payment method support can hinder customer convenience and satisfaction.<br />
                        Statistics show that 50% of online shoppers abandon their purchases due to a lack of seamless payment experiences.

                    </Typography>
                </Stack>

            </Stack>
            <Stack padding={{ xs: "20px", sm: "40px" }} paddingTop={{ xs: "0px", md: "40px" }} flexDirection={{ xs: "column-reverse", md: "row" }} justifyContent={"space-evenly"} alignItems="center" gap="40px">
                <Stack width={{ xs: "100%", md: "45vw" }}>
                    <Typography fontSize={{ xs: "22px", md: "28px" }} sx={{ fontWeight: "600", color: "#3d3d3d", marginBottom: "10px" }}>Solutions</Typography>
                    <Typography fontSize={{ xs: "16px", md: "18px" }} color="#444444">
                        <strong>Unified payment gateway integration:</strong> Streamline payment processes by consolidating multiple payment methods into a single platform.<br />
                        <strong>Robust security measures:</strong> Implement advanced encryption technologies, fraud detection mechanisms, and secure tokenization to protect sensitive customer information.<br />
                        <strong>Enhanced payment options:</strong> Collaborate with financial institutions and industry partners to broaden the range of payment methods and promote interoperability.<br />
                        <strong>Personalized payment experiences:</strong> Leverage advanced analytics to gain insights into customer preferences and behavior, enabling tailored payment experiences.<br />
                        <strong>Seamless and secure transactions:</strong> Foster customer trust and loyalty by providing smooth and secure payment transactions.<br />
                        <strong>Data-driven decision-making:</strong> Utilize real-time data analytics to identify trends, optimize payment processes, and drive business growth.

                    </Typography>
                </Stack>
                <Box
                    component="img"
                    src="./images/Payment/illus1.png"
                    sx={{
                        width: { xs: "70vw", sm: "50vw", md: "40vw", lg: "30vw" },
                        margin: "10px 0px"

                    }}
                />

            </Stack>
            <Browse />
        </>
    );
}

export default Parking;